import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import RecurringTransactionsIcon from "assets/icons/Recurring-Transactions.svg";
import QOnBoardingIndex from "components/layout/QOnBoardingIndex";
import { useRTPermissions } from "hooks/recurring_transactions";

const NoRecurringTransactions = () => {
  const { t } = useTranslation();

  const { isPermissionsLoading, permissions } = useRTPermissions();

  const canWrite: boolean =
    !isPermissionsLoading && (permissions?.recurring_transactions?.write || false);

  return (
    <QOnBoardingIndex
      icon={<RecurringTransactionsIcon />}
      title={t("recurring_transactions.onboarding.title")}
      subtitle={t("recurring_transactions.onboarding.desc")}
      extra={
        <Button
          type="primary"
          href="recurring_transactions/new"
          disabled={!canWrite}
          style={{ height: "auto", whiteSpace: "pre-wrap" }}
        >
          <PlusOutlined /> {t("recurring_transactions.create.button")}
        </Button>
      }
    />
  );
};

export default NoRecurringTransactions;
