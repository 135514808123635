import { useTranslation } from "react-i18next";
import { Button, Divider, Flex, Modal, theme } from "antd";
import Title from "antd/es/typography/Title";
import ColoredDoubleRight from "assets/icons/Colored-Double-Right.svg";
import QIcon from "components/layout/QIcon";

const { useToken } = theme;

interface SkipProcessModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onSkipProcess: () => void;
  isSkipping: boolean;
}

const SkipProcessModal = ({
  isModalOpen,
  setIsModalOpen,
  onSkipProcess,
  isSkipping,
}: SkipProcessModalProps) => {
  const { t } = useTranslation();
  const { token } = useToken();

  return (
    <Modal
      open={isModalOpen}
      centered
      onCancel={() => setIsModalOpen(false)}
      footer={
        <Flex justify="space-between" gap={token.margin}>
          <Button onClick={() => setIsModalOpen(false)}>
            {t("recurring_transactions.actions.cancel")}
          </Button>
          <Button type="primary" onClick={onSkipProcess} loading={isSkipping}>
            {t(`recurring_transactions.actions.${isSkipping ? "skipping" : "skip"}`)}
          </Button>
        </Flex>
      }
      width="max-content"
      style={{ textAlign: "center" }}
    >
      <QIcon component={ColoredDoubleRight} style={{ fontSize: 48 }} />
      <Title level={4}>{t("recurring_transactions.skip_process.title")}</Title>
      <p>{t("recurring_transactions.skip_process.sub_title")}</p>
      <Divider />
    </Modal>
  );
};

export default SkipProcessModal;
